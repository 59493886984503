<template lang="pug">
  .login-block
    .login-block__container
      .login-block__logo.logo: img(src="images/static/logo.svg", alt="logo")
      login-form(v-if="!authStatus" @submit="login" :isLoading="isAuthLoading")
      .hello-message(v-if="!authStatus") {{$t('messages.loginHelloMessage')}}
      .message(v-if="authStatus")
        .login-block__message
          .login-form__error-text(v-html="statusText")
          button(@click="resetAuthStatus" type="button", class="button") {{$t('buttons.goBack')}}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'Login',
  created () {
    document.getElementsByTagName('body')[0].className = 'background-filled'
  },
  methods: {
    ...mapActions(['login', 'resetAuthStatus'])
  },
  computed: {
    ...mapGetters(['isAuthLoading', 'authStatus']),
    statusText () {
      switch (this.authStatus) {
        case 'success':
          return this.$t('messages.successLogin')
        case 'error':
          return this.$t('messages.errorLogin')
        default:
          return ''
      }
    }
  },
  components: { LoginForm }
}
</script>

<style scoped>
  .hello-message {
    color: #fff;
    margin-top: 50px;
  }
</style>
