<template lang="pug">
  form.login-block__form.login-form(@submit.prevent="onSubmit")
    label.login-form__group
      span.login-form__label {{$t('labels.enterEmail')}}
      input(
        type="text"
        v-model="formData.email"
        class="form-input login-form__input"
        placeholder="E-mail"
      )
    .login-form__group
      button(v-if="!isLoading" type="submit", class="button") {{$t('buttons.signIn')}}
      button(v-if="isLoading" type="button", class="button button--is-loading")
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    isLoading: {
      type: Boolean
    }
  },
  data () {
    return {
      formData: {
        email: ''
      }
    }
  },
  methods: {
    onSubmit () {
      this.$emit('submit', this.formData)
    }
  }
}
</script>

<style scoped>

</style>
